import React from 'react';

const PrivacyPolicyEn = () => {
    return <>
        <p>This document explains how to manage the site at the internet address aidacloud.com (the "Site") regarding
           the
           protection of personal data of users.</p>
        <p>This information is provided pursuant to article 13 of Legislative Decree 30 June 2003, n. 196 - Code for the
           protection of personal data.</p>
        <p>This information is also based on Recommendation 2/2001 that the European authorities for the protection of
           personal data gathered by the Group established by Article 29 of Directive 95/46/EC, adopted on May 17, 2001
           to establish minimum requirements for collecting personal data online, and, in particular, the manner, timing
           and nature of the information that data controllers must provide users when they connect to web pages,
           regardless of the purpose of the link.</p>
        <p>This information is provided in accordance with Article 13 of Legislative Decree no. 196 of June 30, 2003 -
           Code for the protection of personal data.</p>
        <p>This information is also provided for all the domains related or in any case belonging to the Site and
           not for other web sites that may be consulted through links.</p>
        <p>We take the protection of our users' privacy very seriously and give the highest priority to ensuring the
           security and confidentiality of the personal data provided by users. Personal information is information
           that can be linked to a specific individual, such as your name, address, date of birth, telephone number
           and/or email address.</p>
        <p>So, we recommend that you provide us with your personal information.</p>
        <p>We therefore recommend that you read this document to learn more about our policy on the protection
           of personal data.</p>
        <h2>The owner of the treatment</h2>
        <p>The data controller of users' personal data collected on the Website is Technology & Cognition LAB
           SRL ("Technology & Cognition LAB") with registered office in Corso Re Umberto, 10 - Turin, TO, Italy.
           Any additional data that may be required for the use or purchase of services or products provided by
           third parties will be collected by these third parties.</p>
        <h2>What personal data are collected and used</h2>
        <p>Technology & Cognition LAB collects personal data specifically and voluntarily provided by users of
           the Site.
           On all pages of the Site that collect personal data, it is specifically described what data is needed
           in order to provide the requested product or service of the user.</p>
        <h2>Mode of data collection</h2>
        <h4>User registration</h4>
        <p>If you register, we will collect personal information that you manually provide by filling out the
           registration form, such as your name, email address, mailing address, phone number, preferences,
           username, password, and contact information for other people.</p>
        <h4>Contacts</h4>
        <p>Personal user information such as first and last name, email address is collected whenever the user
           submits a question or suggestion or requests information or assistance.</p>
        <h4>Other Sources</h4>
        <p>When you link to the Site from a site operated by one of our partners ("click-through"), and you are
           already registered with that partner, we may receive certain information that you provide to that
           partner. We therefore encourage you to review the privacy policy on the partner's site to find out
           what information is collected on that site and what use the partner is permitted to make of it. We
           may not retain this information; if we decide to retain it, we will abide by our own privacy
           statement, regardless of the privacy statement provided by the partner.</p>
        <p>If you link to the Site through one of our partners (whether or not you register with the partner's
           site) and decide to register on the Site, we may be required to provide all or part of your
           registration information to that partner. We will only provide such information in accordance with
           our privacy statement, but we cannot control the use of such data by our partner. If you have any
           concerns about our partner's privacy statement, you should review the statement before providing any
           information to us.</p>
        <h4 id="cookie">Cookies</h4>
        <p>Cookies are small text files stored on the user's PC. Our site uses both session ID (or temporary)
           cookies and persistent cookies. Session ID cookies disappear permanently when you close your browser.
           Persistent cookie files remain on the hard drive of the user's computer until the user manually
           deletes them.
           The Site uses cookies for different purposes. For example, cookies are used to automatically
           recognize whether you have previously logged on to the Site. Cookies are also used to track your
           browsing patterns and to better understand how you use the Site.</p>
        <p>Most Internet browsers automatically accept cookies, but if you prefer to block them, simply
           reconfigure your browser's security settings. However, if you choose the latter option, you will be
           restricted from accessing certain parts of the Site.</p>
        <p>When you visit the Site, information is collected about your computer and a cookie is sent to
           your computer. This information is not provided by you, but is collected automatically in order
           to support your visit to our Site.</p>
        <p>When you visit our Site, information about your computer is collected and a Cookie is sent to
           your Computer.</p>
        <p>If you do not register with the Site, the personal information we collect will be limited. We
           will collect your IP address for the purpose of detecting problems with our server,
           administering the Site, and compiling usage statistics.</p>
        <p>However, if you do not register with the Site, we will not collect any personal information
           about you.</p>
        <p>However, unless you register with the Site, the cookie will not have access to
           information that will allow us to identify you. Cookies are not used to capture
           information on your hard drive and cannot collect information from other cookies on your
           computer from other sites. Our use of cookies is for the purpose of understanding how
           users use our Site and how it can be improved.</p>
        <p>In any case it will be extremely difficult to identify you by your IP address alone and
           no attempt will be made to do so.
           Once you have registered with the Site, your personal information is associated with the
           Cookie and will remain on your computer until you manually delete it.</p>
        <p>The Cookie will not be used to identify you.</p>
        <h4>LogFile</h4>
        <p>Like most servers, we use log files. Log files include IP addresses, server type,
           ISP, referring and exit pages, platform type, time data (date/time), number of
           clicks. These files are used to analyze trends, administer the site, track
           navigation, diagnose server problems, and gather general demographic information
           about IP aggregate usage. The addresses and all other log data are not linked to
           personally identifiable information.</p>
        <h2>Manner of Data Use</h2>
        <p>The data collected on the Site may be used for:</p>
        <h4>Business Communications</h4>
        <p>As a registered user of the Site, you may receive periodic emails from Technology &
           Cognition LAB about evenings and events, information on services or other
           opportunities offered.</p>
        <p>When you register, you are asked to provide contact information such as your name and
           email address. Out of respect for the privacy of our users, we offer the possibility
           of not receiving these commercial communications. To this effect please refer to the
           section of the site "Unregister" (opt-out).</p>
        <h4>Answers to questions or suggestions</h4>
        <p>Technology & Cognition LAB will use your email address or phone number or fax number
           to respond if you submit a question or suggestion or request assistance.</p>
        <p>Request to update or delete data:</p>
        <p>When the user requests a data update or deletion, Technology & Cognition LAB will
           send a confirmation request email.</p>
        <h4>Service communications</h4>
        <p>Sometimes Technology & Cognition LAB may send purely service-related email
           communications, for example, if the site is temporarily suspended for
           maintenance.</p>
        <h4>Quality Enhancement</h4>
        <p>Technology & Cognition LAB collects certain data based on users' navigation of the
           Site in order to improve understanding and service to our users. This data allows us
           to optimize your ability to navigate the Site and to develop customized programs and
           content that are of interest to our registered users and visitors to our Site. No
           personal information is collected unless you explicitly provide it.</p>
        <p>This data may also be used to display targeted advertisements. No personal data is
           used to deliver targeted advertisements. Aggregated demographic information is shared
           by us with our partners and advertisers. This information is not linked to any
           personal data.</p>
        <h4>Authorized Service Providers</h4>
        <p>Technology & Cognition LAB shares your personal information with authorized service
           providers who perform certain functions or services on our behalf. For example, we
           use outside companies to monitor and manage the Site, statistics. We also use service
           providers to respond to orders, perform business data analysis, and provide marketing
           assistance.</p>
        <p>We may contract with a reputable third party to facilitate the delivery of
           communications. </p>
        <h2>User Rights and Data Access</h2>
        <p>The users to whom the personal data refer have the right at any time to obtain
           confirmation of the existence or otherwise of such data and to know the content and
           origin, verify its accuracy or request its integration or updating, or correction
           (art. 7 of Legislative Decree no. 196/2003).</p>
        <p>According to the same article, the user has the right to ask for the cancellation,
           the transformation in anonymous form or the block of the data treated in violation of
           the law, as well as to oppose in any case, for legitimate reasons, to their
           treatment.</p>
        <p>The requests must be addressed to Technology & Cognition LAB by e-mail at the address
           indicated in the section "how to contact us"</p>
        <h2>Information Security</h2>
        <p>We take every precaution to protect the personal information of our users. Only
           employees who need the data for a specific job have access to personal information.
           Finally, the servers that store personally identifiable data are located in a locked
           room, protected by industry-standard passwords and firewalls.</p>
        <h2>Changes to this Privacy Policy</h2>
        <p>This Privacy Policy was prepared and posted to the Site on March 18, 2019.</p>
        <p>Depending on subsequent updates, we will comply with the data privacy practices
           described in this Privacy Policy at the time you provided us with your
           information.</p>
        <p>In the event that we decide to change our privacy policy, those changes will be
           reflected in this Privacy Statement and on the effective date of implementation, so
           that you can always be up-to-date on what information we collect, how we use it, and
           in some cases, whether we disclose it. If there are material changes to this
           statement or to the way Technology & Cognition LAB uses your personal information,
           Technology & Cognition LAB will provide adequate and ample notice before implementing
           them.</p>
        <h2>How to Contact Us</h2>
        <p>For any questions or suggestions regarding our privacy statement, users may contact
           us at: info@tclab.it</p>
    </>;
};

export default PrivacyPolicyEn;
