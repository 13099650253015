import { graphql } from 'gatsby';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { find } from 'lodash';
import * as React from 'react';
import PrivacyPolicyEn from '../components/PrivacyPolicy/privacy-policy-en';
import PrivacyPolicyIt from '../components/PrivacyPolicy/privacy-policy-it';
import { Seo } from '../components/Seo';
import Layout from '../layouts/layout';

const PrivacyPolicyPage = props => {
    const { language } = useI18next();

    let policyComponent = language === 'it' ? <PrivacyPolicyIt /> : <PrivacyPolicyEn />;

    return <Layout location={props.location}>
        <div className="container p-0" style={{ margin: '60px auto' }}>
            <h1 style={{ marginBottom: '20px' }}><Trans>Privacy policy</Trans></h1>
            {policyComponent}
        </div>
    </Layout>;
};

export default PrivacyPolicyPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
        filter: {ns: {in: ["common", "privacy-policy-page"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
    const edge = find(data.locales.edges, [ 'node.ns', 'privacy-policy-page' ]);
    let obj = undefined;
    if (!!edge) {
        obj = JSON.parse(edge.node.data);
    }
    const title = !!obj ? obj.title : 'Privacy policy';

    return <Seo title={title} description={!!obj ? obj.description : null} />;
};

